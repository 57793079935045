@use "sass:map";
@import "../../styles/variables";
@import "../../styles/media_queries";

.darkBackground {
  background-color: $bg-container;

  .header {
    display: flex;
    padding: 0.75rem 2rem 1.12rem 2rem;
    align-items: center;
    justify-content: space-between;
    background-color: $bg-container;
    color: map.get($theme-colors, "gray");
    gap: 1rem;
    max-width: 1440px;
    margin: auto;
    @include mulish-regular;

    @include breakpoint-under("xl") {
      display: none;
    }

    .logo {
      display: block;
    }

    .buttonContainer {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      a {
        white-space: nowrap;
        &:hover {
          color: map.get($theme-colors, "extra-dark") !important;
        }
      }
    }

    a {
      cursor: pointer;
      height: 45px;
      box-sizing: border-box;
      transition: all 0.5s ease;

      &:hover {
        color: map.get($theme-colors, "light");
      }
    }
  }
}

.expandableItem {
  & > a::after {
    content: '';
    display: inline-block;
    width: 0.3em;
    height: 0.3em;
    border-right: solid 2px #d2d2d2;
    border-bottom: solid 2px #d2d2d2;
    transform: translateY(-0.15em) rotate(45deg);
    margin-left: 0.5em;
  }

  &.expandableItemExpanded > a::after {
    transform: translateY(0.05em) rotate(-135deg);
  }
  &.expandableItemExpanded .subMenu {
    max-height: 400px;
  }
}

.subMenu {
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 40px;
  z-index: 1;
  background-color: map.get($theme-colors, "dark");
  /*padding-top: 100px;
  padding-bottom: 50px;*/
  & > div:first-child {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    &::before {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-bottom: solid 1px map.get($theme-colors, "gold");
    }
  }
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s;
  .closeContainer {
    position: absolute;
    top: 50px;
    left: 50%;
    box-sizing: border-box;
    padding: 0 32px;
    transform: translateX(-50%);
    text-align: right;
    width: 100%;
    max-width: 1500px;
  }
  .close {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &::before, &::after {
      content: '';
      position: absolute;
      display: block;
      width: 20px;
      height: 2px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: #fff;
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}