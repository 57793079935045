@use "sass:map";
@import "../../styles/_variables.scss";
@import "../../styles/media_queries";

.blockDocument {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.langSelector {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: $bg-upper-header;

    @include breakpoint-under("lg") {
        margin: 0;
    }
}

.localeSelect {
    background: transparent;
    @include mulish-light;
    z-index: 4;

    [class$="-control"] {
        cursor:pointer;
        background: transparent;
        border: none;
        min-width: 40px;

        * {
            color: map.get($theme-colors, "gold");
            padding: 0;
        }
    }

    [class$="-indicatorSeparator"] {
        display: none;
    }

    [class$="-menu"] {
        background: $bg-upper-header;
    }
}

.upperHeaderDesktop {
    background-color: $bg-upper-header;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: .5rem;
    color: map.get($theme-colors, "gold");
    max-height: $upperHeaderHeight;
    height: 100%;
    position: sticky;
    top: 0;
    z-index: 4;
    @include mulish-light;

    label {
        @include mulish-light;
        color: map.get($theme-colors, "intermiate-gray");
    }

    @include breakpoint-under("xl") {
        display: none;
    }

    .personal {
        height: max-content;
        display: flex;
        align-items: center;
        gap: 0.125rem;
        font-size: .8125rem;
        @include mulish-semi-bold;
        .personalIcon {
            margin: 0.5rem;
        }
    }
}

.upperHeaderMobile {
    background-color: $bg-container;
    padding: 3rem 1rem;
    display: flex;
    justify-content: space-between;
    color: map.get($theme-colors, "light");
    // width: 100%;
    gap: 1rem;
    align-items: center;

    @include breakpoint-over("xl") {
        display: none;
    }

    .burgerContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        .personalIcon {
            display: none;
            font-size: 1.5rem;
            margin-right: 0.5rem;

            @include breakpoint-under("xl") {
                display: block;
                svg {
                    height: 25px;
                    width: 35px;
                }
                path {
                    stroke: map.get($theme-colors, "light");
                }
            }
        }

        .burgerIcons {
            display: none;

            @include breakpoint-under("xl") {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 2rem;
                height: 2rem;
                gap: 0.35rem;
                background: transparent;
                border: none;
                cursor: pointer;
                padding: 0;
                z-index: 10;
            }

            div {
                width: 1.75rem;
                height: 0.15rem;
                background-color: map.get($theme-colors, "light");
                border-radius: 10px;
                transition: all 0.4s linear;
                position: relative;
                transform-origin: 1px;

                &.open:nth-child(1) {
                    top: -1.5px;
                    transform: rotate(45deg);
                }

                &.open:nth-child(2) {
                    opacity: 0;
                    transform: translate(20px);
                }

                &.open:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
