@use "sass:map";
@import "../../styles/_variables";
@import "../../styles/_media_queries";

.footer {
  background-color: map.get($theme-colors, "extra-dark");
  width: 100%;
  color: map.get($theme-colors, "light");
  line-height: 1.44;
  overflow: hidden;

  .clubButton {
    width: 214px;
    line-height: 2;

    @include breakpoint-under("xl") {
      width: 246px !important;
    }

    a {
      &:hover {
        color: map.get($theme-colors, "extra-dark") !important;
      }
    }
  }

  .infoButton {
    display: none;

    line-height: 2;

    @include breakpoint-under("xl") {
      display: none;
    }
  }

  .footerImg {
    height: 300px;
    background-size: cover;
    background-position: center;
    display: flex;

    .darkBackground {
      background-image: linear-gradient(rgba(0, 0, 0, 0.75));
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .75)));
      width: 100%;
      padding: 1.88rem;

      @include breakpoint-under("md") {
        padding: 1.25rem;
      }

      .heroContent {
        border: solid 1px rgba($color: map.get($theme-colors, "gold"), $alpha: 0.3);
        @include unna-italic;
        font-size: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: auto;
        justify-content: center;
        gap: 1rem;
        text-align: center;
        padding: 0 4.7rem;
        box-sizing: border-box;

        @include breakpoint-under("md") {
          font-size: 1.5rem;
        }

        .title {
          @include mulish-regular;
          color: map.get($theme-colors, "light-gold");
          font-style: normal;
          font-size: 1rem;
        }

        .button {
          width: 11.56rem;

          @include breakpoint-under("md") {
            width: auto;
          }
        }
      }
    }
  }

  .container {
    max-width: 1380px;
    margin: auto;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 2rem;
    overflow: hidden;

    @include breakpoint-under("xl") {
      flex-direction: column;
    }

    @include breakpoint-under("xl") {
      flex-wrap: wrap;
      gap: 1.5rem;
      max-width: 100%;
      text-align: center;
    }
  }

  // .footerImg {
  //   width: 100%;
  //   height: 35vh;

  //   img {
  //     height: 100%;
  //     width: 100%;
  //   }
  // }

  .footerBrand {
    padding: 2rem 0;

    @include breakpoint-under("xl") {
      padding: 2rem 0 0 0;
      margin: auto;
    }

    a {
      // color: #a9a9a9;
      text-decoration: none;
    }

    a:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }

  .footerBrandInfo {
    border-bottom: 1px solid map.get($theme-colors, "dark-gold");

    @include breakpoint-under("xl") {
      border-bottom: none;
    }

    div {
      @include unna-italic;
      align-self: center;

      img {
        display: inline;
      }
    }

    @include breakpoint-under("xl") {
      div {
        width: 100%;
        text-align: center;
      }
    }

    .footerClub {
      max-width: 820px;
      display: flex;

      @include breakpoint-under("xxl") {
        max-width: 705px;
      }

      @include breakpoint-under("xl") {
        display: flex;
        flex-direction: column;
        text-align: center;
      }
    }

    .footerClubTitle {
      white-space: nowrap;
      font-size: 1.5em;
      margin: 0 1rem 0 0;

      @include breakpoint-under("xl") {
        margin: 0;
      }
    }

    .footerClubDesc {
      font-size: 1.375em;
    }
  }

  .footerBrandRef {
    @include mulish-regular;
    font-size: 1rem;

    // @include breakpoint-under("md") {
    //   div {
    //     width: 100%;
    //     text-align: center;
    //   }
    // }

    .footerColumn {
      height: fit-content;
      display: flex;
      flex-direction: column;
    }

    .download {
      border-bottom: 1px solid map.get($theme-colors, "dark-gold");
      border-top: 1px solid map.get($theme-colors, "dark-gold");
      width: 100%;
      padding-top: 2.2rem;
      padding-bottom: 4rem;
    }

    .footerColumnWrap {
      display: flex;
      flex-direction: column;
      gap: 1.9rem;
    }

    .footerBrandRefLinkTitle {
      margin: 0 0 0.5rem 0;
      color: map.get($theme-colors, "gold");
    }

    .footerBrandRefLinks {
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 3.5rem;

      @include breakpoint-under("xl") {
        flex-direction: column;
        gap: 2.2rem;
      }

      @include breakpoint-under("footer_xl") {
        justify-content: flex-start;
        gap: 1.5rem;
      }

      @include breakpoint-under("md") {
        text-align: center;
        gap: 2rem;
      }

      .footerFirstSection {
        // max-width: 307px;

        button {
          @include breakpoint-under("xl") {
            display: none;
          }
        }

        .footerFirstSectionText {
          color: map.get($theme-colors, "gray");
          margin-bottom: 1.5rem;
        }

        .linksContainer {
          margin-bottom: 1.5rem;
          display: flex;
          gap: 0.69rem;

          @include breakpoint-under("xl") {
            margin-bottom: 0;
            justify-content: center;
          }

          a {
            color: map.get($theme-colors, "gray");
            transition: all .5s ease;

            &:hover {
              color: map.get($theme-colors, "footer-light");
            }
          }

          a[href^="mailto:"] {
            text-decoration: underline;
          }
        }

        .info {
          display: none;

          color: $bg-button;
          margin-bottom: 1.4rem;

          @include breakpoint-under("xl") {
            display: none;
          }
        }
      }
    }

    .footerLink {
      color: map.get($theme-colors, "gray");
      line-height: 2.3125;

      p {
        margin: 0;
        line-height: 1.75;
      }
    }

    .footerBrandDownloadApp {
      flex-basis: 20%;

      @include breakpoint-under("md") {
        flex-basis: 100%;
        border-top: 1px solid map.get($theme-colors, "dark-gold");
        padding: 2rem 0;
      }
    }

  }

  .footerBrandNewsletter {
    flex-basis: 25%;
    padding: 0 1.5rem 0 0;

    @include breakpoint-under("footer_xl") {
      top: -360px !important;
    }

    @include breakpoint-under("xl") {
      padding: 2rem 1rem 2rem 1rem;
      top: unset;
    }

    label {
      cursor: text;
      position: relative;

      @include breakpoint-under("xl") {
        margin: 3rem auto auto;
      }
    }

    .placeholder {
      @include mulish-regular;
      color: map.get($theme-colors, "gray");
      position: relative;
      left: 15px;
      top: 15px;
      transition: 0.2s;
      display: block;
      transition-timing-function: ease;
      width: fit-content;
      font-size: .94rem;
      position: absolute;
    }

    input:focus+span,
    input:not(:placeholder-shown)+span {
      top: -10px;
      padding: 0 1rem;
      background-color: map.get($theme-colors, "extra-dark");
    }

    input {
      &:focus {
        outline: none !important;
      }
    }

    .footerBrandNewsletterTitle {
      @include unna-italic;
      font-size: 1.5rem;

      span:first-child {
        margin-right: 0.5rem;
      }
    }

    .newsletterInput {
      box-sizing: border-box;
      display: block;
      border: 0.15px solid map.get($theme-colors, "gray");
      border-radius: 0;
      margin: 1rem auto;
      padding: 1rem;
      width: 333.63px;
      height: 55px;

      input {
        background: none;
        border: none;
        color: map.get($theme-colors, "gray");
        @include mulish-regular;
        font-size: 1.25rem;
      }

      button {
        background: transparent;
        border: none;
        transform: scale(2);
        position: absolute;
        // left: 275px;
        // bottom: 46px;
        top: 20px;
        left: 288px;

        // @include breakpoint-under("xl"){
        //   left: 138px;
        // }
      }
    }
  }

  .footerLow {
    border-top: 1px solid map.get($theme-colors, "dark-gold");
    padding: 1.125rem 0;
    gap: 2rem;
    @include mulish-regular;
    position: relative;

    @include breakpoint-under("xl") {
      width: auto;
    }

    @include breakpoint-under("xl") {
      padding: 0;

      // div {
      //   width: 100%;
      //   text-align: center;
      //   margin: auto;
      // }

      // div:first-child {
      //   border-bottom: 1px solid map.get($theme-colors, "dark-gold");
      // }

      .footerLowSocialContainer {
        border: none !important;
      }
    }

    .footerLowSocialContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      gap: 1rem;
      align-items: center;
      padding: 0 2.25rem;

      @include breakpoint-under("xl") {
        justify-content: center;
      }

      @include breakpoint-under("xl") {
        padding-top: 2.56rem;
        padding-bottom: 2.56rem;
      }

      @include breakpoint-under("md") {
        padding: 2.5rem;

        span {
          display: none;
        }
      }

      .facebook {
        z-index: 5;

        @include breakpoint-under("md") {
          display: none;
        }
      }

      .fbMobile {
        display: none;

        @include breakpoint-under("md") {
          display: block;
        }
      }

      .socialIconContainer {
        color: map.get($theme-colors, "gray");
        transition: all 0.5s ease;
        font-size: 1.25rem;

        img {
          font-size: 1rem;
          transition: all 0.5s ease;

          &:hover {
            filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(260deg) brightness(150%) contrast(200%);
          }
        }
      }
    }

    .copywright {
      color: map.get($theme-colors, "gray");
      padding: 0 2.25rem;

      @include breakpoint-under("xl") {
        padding-top: 2.56rem;
        padding-bottom: 3.81rem;
      }

      @include breakpoint-under("md") {
        padding: 2rem 1rem;
      }

      p {
        margin: 0;
      }
    }
  }

  .facebookIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;

    a {
      gap: .3rem;
      display: flex;
      transition: all .5s ease;

      &:hover {
        color: map.get($theme-colors, "light");

        img {
          transition: all 0.5s ease;
          filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(260deg) brightness(150%) contrast(200%);
        }
      }
    }

    span {
      display: block !important;
      font-size: .85rem;
    }
  }
}

:global(.lang-ru) {

  .footerBrandRef {
    font-size: .9rem;

    .footerBrandRefLinks {
      gap: 2rem;
    }

    .container {
      max-width: 1380px;
      flex-wrap: nowrap;

      @media screen and (max-width: 1400px) {
        flex-wrap: wrap;
      }
    }
  }
}

:global(.lang-fr) {

  .footerBrandRef {
    font-size: .9rem;

    .footerBrandRefLinks {
      gap: 2.5rem;
    }

    .container {
      max-width: 1380px;
      flex-wrap: nowrap;

      @media screen and (max-width: 1400px) {
        flex-wrap: wrap;
      }
    }
  }
}

:global(.lang-jp) {

  .footerBrandRef {
    font-size: .9rem;

    .container {
      max-width: 1380px;
      flex-wrap: nowrap;

      @media screen and (max-width: 1400px) {
        flex-wrap: wrap;
      }
    }
  }
}
.footerBrandRef {
  font-size: .9rem;

  .container {
    max-width: 1380px;
    flex-wrap: nowrap;

    @media screen and (max-width: 1400px) {
      flex-wrap: wrap;
    }
  }
}

.mailContainer {
  font-family: "Mulish", sans-serif;
  font-weight: 300;
  color: #a9a9a9;

  a {
    text-decoration: underline !important;
    @include mulish-regular;
    align-self: flex-end;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      color: map.get($theme-colors, "medium-gold") !important;
    }
  }
}