@use "sass:map";
@import "../../styles/variables";
@import "../../styles/media_queries";

.MenuMobile {
    top: 0;
    right: 0;
    position: fixed;
    background-color: $bg-container;
    z-index: 4;
    width: 100%;
    height: 100%;
    display: none;
    transform: translate(-100%);
    transition: all 0.4s ease-out;
    padding-top: 11.5rem;
    overflow: hidden;
    /*justify-content: center;*/

    &.subExpanded {
      color: inherit;

      .menuMobileMain {
        transform: translateX(-100%);
      }
      .menuMobileSubmenu {
        transform: translateX(0);
      }
    }

    @include breakpoint-under("xl") {
        display: flex;
        flex-direction: column;
        align-items: center;
        & > div:first-child {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }

        .menuMobileItem {
            cursor: pointer;
            margin: .5rem;
            text-align: center;
            font-size: 1.25rem;
            color: map.get($theme-colors, "light");
            @include mulish-regular;
        }
        .itemExpandable {
          position: relative;
          padding-left: 1em;
          padding-right: 1em;
          a::after {
            content: '';
            position: absolute;
            display: inline-block;
            top: 50%;
            width: 0.3em;
            height: 0.3em;
            border-right: solid 2px currentColor;
            border-bottom: solid 2px currentColor;
            margin-left: 0.5em;
            transform: translateY(-0.15em) rotate(-45deg);
          }
        }
        .menuMobileButtonContainer {
            margin-top: 1.5rem;
            max-width: 200px;
            display: flex;
            gap: 1.125rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .submenuBack {
          border-top: solid 1px map.get($theme-colors, "gold");
          border-bottom: solid 1px map.get($theme-colors, "gold");
          
          display: block;
          padding: 15px 8px;
          margin: 0 -8px 10px -8px;
          text-align: left;

          &::before {
            content: '';
            display: inline-block;
            top: 50%;
            width: 0.3em;
            height: 0.3em;
            border-left: solid 2px currentColor;
            border-bottom: solid 2px currentColor;
            margin-right: 0.5em;
            margin-left: 18px;
            transform: translateY(-0.15em) rotate(45deg);
          }
        }
        .menuMobileMain {
          color: inherit;
          transition: all 0.3s;
        }
        .menuMobileSubmenu {
          display: flex;
          flex-direction: column;
          width: 100%;
          position: absolute;
          transition: all 0.3s;
          transform: translateX(100%);
        }
    }

    &.open {
        transform: translate(0);
        width: 100%;
        height: 100%;
    }
}
