@use "sass:map";
@import "../../styles/variables";

.Loader {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10;
    background: rgba($color: #000000, $alpha: 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
    transition: all .5s ease;

    &.invisible {
        background: transparent;
        opacity: 0;
        visibility: hidden;
    }

    .spinner {
        width: 40px;
        height: 40px;
        position: relative;
        margin: 100px auto;
    }

    .doubleBounce1,
    .doubleBounce2 {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: map.get($theme-colors, "gold");
        opacity: 0.6;
        position: absolute;
        top: 0;
        left: 0;

        -webkit-animation: sk-bounce 2s infinite ease-in-out;
        animation: sk-bounce 2s infinite ease-in-out;
    }

    .doubleBounce2 {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }

    @-webkit-keyframes sk-bounce {
        0%,
        100% {
            -webkit-transform: scale(0);
        }
        50% {
            -webkit-transform: scale(1);
        }
    }

    @keyframes sk-bounce {
        0%,
        100% {
            transform: scale(0);
            -webkit-transform: scale(0);
        }
        50% {
            transform: scale(1);
            -webkit-transform: scale(1);
        }
    }
}
